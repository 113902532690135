<template>
    <v-layout justify-center>
        <v-form @submit.prevent="userRegistration">
            <div class="text-h4 mb-5 mt-5">Sign Up</div>

            <v-item-group>
                <label>Name</label>
                <v-text-field v-model="user.name" />
            </v-item-group>

            <v-item-group>
                <label>Email</label>
                <v-text-field type="email" v-model="user.email" />
            </v-item-group>

            <v-item-group>
                <label>Family Id</label>
                <v-text-field v-model="user.familyId" />
            </v-item-group>

            <v-item-group>
                <label>Password</label>
                <v-text-field type="password" v-model="user.password" />
            </v-item-group>

            <v-btn type="submit" dark large color="teal">
               Sign Up
            </v-btn>

            <p class="forgot-password text-right mt-5">
                Already registered
                <router-link :to="{name: 'login'}">sign in?</router-link>
            </p>
        </v-form>
    </v-layout>
</template>

<script>
import firebase from 'firebase'
import User from '../../user/User'
import Family from '../../family/Family'

export default {
  data () {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        familyId: '',
        uid: ''
      }
    }
  },
  methods: {
    userRegistration () {
      const family = new Family(this.user.familyId)

      if (family.id === null) {
        alert('Die angegebene FamilyId ist nicht gültig')
        return
      }

      family.FamilyIdExists().then((exists) => {
        if (exists) {
          firebase
            .auth()
            .createUserWithEmailAndPassword(this.user.email, this.user.password)
            .then((res) => {
              this.user.uid = res.user.uid
              res.user
                .updateProfile({
                  displayName: this.user.name,
                  photoURL: 'https://res.cloudinary.com/da8mew2gy/image/upload/avatar-1577909_640_dxcyoz.png'
                })
                .then(() => {
                  const user = new User(this.user.name, this.user.email, this.user.uid)
                  user.CreateUser(user, this.user.familyId)
                  this.$router.push('/login')
                })
              alert('Nutzer wurde erstellt')
            })
            .catch((error) => {
              alert(error.message)
            })
        } else {
          alert('Die angegebene FamilyId ist nicht existent')
        }
      })
    }
  }
}
</script>
